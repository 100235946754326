import React, { useState, lazy, Suspense } from "react";
import { useLocation, Route, Switch } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { initializeIcons, ThemeProvider } from "@fluentui/react";
import Nav from "./components/nav/nav";
import GuidGeneratorWrapper from "./views/guid/guidGenerator/guid-generator-wrapper";
import GuidValidator from "./views/guid/guidValidator/guid-validator";
import HexRbg from "./views/css/color-picker/color-picker";
import Header from "./components/header/header";
import DeviceContext from "./contexts/device-context";
import { DeviceEnum } from "./enums/enums";
import breakpoint from "./constants/breakpoints";
import dark from "./themes";
import Utils from "./common/utils";
var Home = lazy(function () { return import("./views/home"); });
var EpochConverter = lazy(function () { return import("./views/date-time/epoch-converter/epoch-converter"); });
var EncodeDecode = lazy(function () { return import("./views/text/url-encode-decode/encode-decode"); });
var BeautifyString = lazy(function () { return import("./views/text/beautify-string/beautify-string"); });
var Base64Enconding = lazy(function () { return import("./views/text/base-64/base-64"); });
var SvgViewer = lazy(function () { return import("./views/image/svg-viewer/svg-viewer"); });
var BabyDeveloper = lazy(function () { return import("./views/baby/baby-developer/baby-developer"); });
var NotFound = lazy(function () { return import("./views/not-found/not-found"); });
initializeIcons("/");
var App = function () {
    var location = useLocation();
    var isSmallDevice = useMediaQuery({
        query: "(max-width: " + breakpoint.smallDevice + "px)",
    });
    var _a = useState(!isSmallDevice), isMenuVisible = _a[0], setIsMenuVisible = _a[1];
    var getDeviceType = function () { return ({
        deviceSize: isSmallDevice ? DeviceEnum.Small : DeviceEnum.Big,
        hasFloatMenu: isSmallDevice,
    }); };
    var setMenuVisility = function () {
        setIsMenuVisible(!isMenuVisible);
    };
    React.useEffect(function () {
        Utils.setMetaData(location.pathname);
    }, [location]);
    return (React.createElement(DeviceContext.Provider, { value: getDeviceType() },
        React.createElement(ThemeProvider, { applyTo: "body", theme: dark },
            React.createElement(Header, { onMenuClick: setMenuVisility }),
            React.createElement("section", null,
                React.createElement(Nav, { showMenu: isMenuVisible }),
                React.createElement(Suspense, { fallback: "loading" },
                    React.createElement("article", null,
                        React.createElement(Switch, null,
                            React.createElement(Route, { path: "/", exact: true, component: Home }),
                            React.createElement(Route, { path: "/uuidgenerator/", component: GuidGeneratorWrapper }),
                            React.createElement(Route, { path: "/guidgenerator/", component: GuidGeneratorWrapper }),
                            React.createElement(Route, { path: "/uuidvalidator", exact: true, component: GuidValidator }),
                            React.createElement(Route, { path: "/guidvalidator", exact: true, component: GuidValidator }),
                            React.createElement(Route, { path: "/epochconverter", exact: true, component: EpochConverter }),
                            React.createElement(Route, { path: "/urlencoding", exact: true, component: EncodeDecode }),
                            React.createElement(Route, { path: "/beautifystring", exact: true, component: BeautifyString }),
                            React.createElement(Route, { path: "/base64encoding", exact: true, component: Base64Enconding }),
                            React.createElement(Route, { path: "/svgviewer", exact: true, component: SvgViewer }),
                            React.createElement(Route, { path: "/colorpicker", exact: true, component: HexRbg }),
                            React.createElement(Route, { path: "/babydev", exact: true, component: BabyDeveloper }),
                            React.createElement(Route, { component: NotFound }))))),
            React.createElement("footer", null))));
};
export default App;
