/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line no-shadow
export var DeviceEnum;
(function (DeviceEnum) {
    DeviceEnum[DeviceEnum["Small"] = 0] = "Small";
    DeviceEnum[DeviceEnum["SmallPortrait"] = 1] = "SmallPortrait";
    DeviceEnum[DeviceEnum["Big"] = 2] = "Big";
    DeviceEnum[DeviceEnum["BigPortrait"] = 3] = "BigPortrait";
})(DeviceEnum || (DeviceEnum = {}));
