import React, { useContext } from "react";
import { Nav as FluentNav } from "@fluentui/react";
import { useHistory } from "react-router-dom";
import ComponentStyles from "./nav-style";
import DeviceContext from "../../contexts/device-context";
import navLinkGroups from "./nav-items";
var Nav = function (props) {
    var deviceContext = useContext(DeviceContext);
    var styles = new ComponentStyles(deviceContext.deviceSize).getStyle();
    var history = useHistory();
    var onClick = function (e, item) {
        e === null || e === void 0 ? void 0 : e.preventDefault();
        if ((item === null || item === void 0 ? void 0 : item.url) === "") {
            // eslint-disable-next-line no-param-reassign
            item.isExpanded = !item.isExpanded;
        }
        else {
            history.push((item === null || item === void 0 ? void 0 : item.url) || "/");
        }
    };
    var showMenu = props.showMenu;
    return (React.createElement("div", { style: styles.container, hidden: !showMenu && deviceContext.hasFloatMenu },
        React.createElement("div", { style: styles.navContainer },
            React.createElement(FluentNav, { onLinkClick: onClick, styles: styles.navigationStyles, groups: navLinkGroups })),
        React.createElement("div", { style: styles.moreTools },
            React.createElement("span", null, "more mini tools soon..."),
            React.createElement("a", { href: "mailto:minidevkit@outlook.com" }, "Suggest a tool"))));
};
export default React.memo(Nav);
