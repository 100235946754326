import React, { useRef, useState, lazy, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import componentStyles from "./guid-generator-styles";
var Dropdown = React.lazy(function () {
    return import("@fluentui/react/lib/components/Dropdown").then(function (module) { return ({
        default: module.Dropdown,
    }); });
});
var Checkbox = React.lazy(function () {
    return import("@fluentui/react/lib/components/Checkbox").then(function (module) { return ({
        default: module.Checkbox,
    }); });
});
var PrimaryButton = React.lazy(function () {
    return import("@fluentui/react/lib/components/Button/PrimaryButton/PrimaryButton").then(function (module) { return ({
        default: module.PrimaryButton,
    }); });
});
var GuidGeneratorDescription = lazy(function () {
    return import("../share-components/guid-generator-descriptions/guid-generator-descriptions");
});
var GuidGeneratorOptions = lazy(function () { return import("./components/guid-generator-options/guid-generator-options"); });
var CopyIcon = lazy(function () { return import("../../../components/copy-icon/copy-icon"); });
var Flex = lazy(function () { return import("../../../components/flex/flex"); });
var Main = lazy(function () { return import("../../../components/flex/main/main"); });
var Side = lazy(function () { return import("../../../components/flex/side/side"); });
var BigInput = lazy(function () { return import("../../../components/big-input/big-input"); });
var GuidGenerator = function (props) {
    var guidVersion = props.guidVersion, baseUrl = props.baseUrl;
    var history = useHistory();
    var inputRef = useRef(null);
    var guidVersionOptions = [
        {
            key: "1",
            text: "1",
        },
        {
            key: "3",
            text: "3",
        },
        {
            key: "4",
            text: "4",
        },
        {
            key: "5",
            text: "5",
        },
    ];
    var getUuid = function (version, name, namespace) {
        if (name === void 0) { name = ""; }
        if (namespace === void 0) { namespace = ""; }
        return import("uuid").then(function (_a) {
            var uuidv1 = _a.v1, uuidv3 = _a.v3, uuidv4 = _a.v4, uuidv5 = _a.v5;
            switch (version) {
                case "1":
                    return uuidv1();
                case "3":
                    return name === "" || namespace === ""
                        ? ""
                        : uuidv3(name, namespace);
                case "4":
                    return uuidv4();
                case "5":
                    return name === "" || namespace === ""
                        ? ""
                        : uuidv5(name, namespace);
                default:
                    return uuidv4();
            }
        });
    };
    var autoGenerateInLoad = ["1", "4"];
    var _a = useState(""), guidNamespaceValue = _a[0], setGuidNamespaceValue = _a[1];
    var _b = useState(false), isValidGuidNamespaceValue = _b[0], setIsValidGuidNamespaceValue = _b[1];
    var _c = useState(""), guidStringValue = _c[0], setGuidStringValue = _c[1];
    var _d = useState(""), guidValue = _d[0], setGuidValue = _d[1];
    var _e = useState(false), uppercase = _e[0], setUppercase = _e[1];
    var applyOptions = useCallback(function (uuid) { return (uppercase ? uuid.toUpperCase() : uuid.toLowerCase()); }, [uppercase]);
    var changeGuidVersion = useCallback(function (_event, selectedOption) {
        if (selectedOption) {
            history.push(baseUrl + "/version" + selectedOption.key);
        }
        else {
            history.push(baseUrl);
        }
    }, [baseUrl]);
    useEffect(function () {
        setGuidValue("");
        if (autoGenerateInLoad.includes(guidVersion))
            getUuid(guidVersion).then(function (uuid) {
                setGuidValue(uuid);
            });
    }, [guidVersion]);
    var onNamespaceChange = useCallback(function (value) {
        import("uuid").then(function (_a) {
            var uuidValidate = _a.validate;
            setGuidNamespaceValue(value);
            setIsValidGuidNamespaceValue(uuidValidate(value));
        });
    }, []);
    var onStringChange = useCallback(function (value) {
        setGuidStringValue(value);
    }, []);
    var onUppercaseChange = useCallback(function (_ev, isChecked) {
        setUppercase(isChecked || false);
    }, []);
    var isNamespaceNeeded = function () {
        return guidVersion === "3" || guidVersion === "5";
    };
    var isDataValid = function () {
        return isNamespaceNeeded()
            ? guidNamespaceValue !== "" &&
                isValidGuidNamespaceValue &&
                guidStringValue !== ""
            : true;
    };
    var generateGuid = useCallback(function () {
        getUuid(guidVersion, guidStringValue, guidNamespaceValue).then(function (uuid) {
            setGuidValue(uuid);
        });
    }, [guidVersion, guidStringValue, guidNamespaceValue]);
    return (React.createElement(Flex, null,
        React.createElement(Main, null,
            React.createElement("h1", null, "UUID Generator"),
            React.createElement(Dropdown, { styles: componentStyles.dropdown, selectedKey: guidVersion, label: "UUID Version", options: guidVersionOptions, onChange: changeGuidVersion }),
            React.createElement("div", null,
                isNamespaceNeeded() ? (React.createElement(GuidGeneratorOptions, { onNamespaceChange: onNamespaceChange, onStringChange: onStringChange, nameSpaceValue: guidNamespaceValue, stringValue: guidStringValue, isValidGuidNamespaceValue: isValidGuidNamespaceValue })) : null,
                React.createElement("div", { style: componentStyles.guidGeneratorContainer },
                    React.createElement("div", { style: componentStyles.guidBigInputDiv },
                        React.createElement(BigInput, { readOnly: true, value: applyOptions(guidValue), inputRef: inputRef }),
                        React.createElement(CopyIcon, { inputRef: inputRef })),
                    React.createElement("div", { style: componentStyles.guidOptions },
                        React.createElement(Checkbox, { label: "Uppercase", styles: componentStyles.checkBox, onChange: onUppercaseChange })),
                    React.createElement(PrimaryButton, { disabled: !isDataValid(), title: "Generate new UUID", style: componentStyles.generateGuidButton, onClick: function () { return generateGuid(); } }, "Generate")))),
        React.createElement(Side, null,
            React.createElement(GuidGeneratorDescription, { version: guidVersion }))));
};
export default React.memo(GuidGenerator);
