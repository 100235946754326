var palette = {
    palette: {
        themePrimary: "#b74803",
        themeLighterAlt: "#eff6fc",
        themeLighter: "#deecf9",
        themeLight: "#c7e0f4",
        themeTertiary: "#71afe5",
        themeSecondary: "#EA620B",
        themeDarkAlt: "#EA620B",
        themeDark: "#553903",
        themeDarker: "#553903",
        neutralLighterAlt: "#999154",
        neutralLighter: "#553903",
        neutralLight: "#171616",
        neutralQuaternaryAlt: "#EA620B",
        neutralQuaternary: "#C5C5C5",
        neutralTertiaryAlt: "#444aaa",
        neutralTertiary: "#c8c8c8",
        neutralSecondary: "#d0d0d0",
        neutralPrimaryAlt: "#dadada",
        neutralPrimary: "#C5C5C5",
        neutralDark: "#f4f4f4",
        black: "#C5C5C5",
        white: "#171616",
    },
    components: {
        PrimaryButton: {
            styles: {
                root: {
                    color: "fff",
                    backGround: "red",
                },
            },
        },
    },
};
export default palette;
