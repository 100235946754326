var navLinkGroups = [
    {
        links: [
            {
                name: "UUID Generator",
                url: "/uuidgenerator",
                key: "uuidgenerator",
            },
            {
                name: "UUID Validator",
                url: "/uuidvalidator",
                key: "uuidvalidator",
            },
            {
                name: "Epoch Converter",
                url: "/epochconverter",
                key: "epochconverter",
            },
            {
                name: "Url Encoding",
                url: "/urlencoding",
                key: "urlencoding",
            },
            {
                name: "Beautify String",
                url: "/beautifystring",
                key: "beautifystring",
            },
            {
                name: "Base64 Encoding",
                url: "/base64encoding",
                key: "base64encoding",
            },
            {
                name: "Svg Viewer",
                url: "/svgviewer",
                key: "svgviewer",
            },
            {
                name: "Color Picker",
                url: "/colorpicker",
                key: "colorpicker",
            },
            {
                name: "Baby Mode (Beta)",
                url: "/babydev",
                key: "babydev",
            },
        ],
    },
];
export default navLinkGroups;
