import React, { useContext } from "react";
import MediaQuery from "react-responsive";
import { IconButton } from "@fluentui/react";
import Typewriter from "typewriter-effect";
import Logo from "../../assets/svg/tool-box.svg";
import ComponentStyles from "./header-style";
import DeviceContext from "../../contexts/device-context";
import breakpoint from "../../constants/breakpoints";
var Header = function (props) {
    var styles = new ComponentStyles(useContext(DeviceContext).deviceSize).getStyle();
    var logoImageRender = function () { return (React.createElement("img", { style: styles.logo, src: Logo, alt: "tool-box", width: "33px", height: "33px" })); };
    var logoTextRender = function () { return (React.createElement(Typewriter, { onInit: function (typewriter) {
            typewriter
                .typeString("miniDevKit.com")
                .start()
                .changeDelay(750)
                .pauseFor(800)
                .deleteChars(4);
        } })); };
    var smallDeviceRender = function () { return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: styles.menuContainer },
            React.createElement(IconButton, { styles: styles.menu, onClick: props.onMenuClick, iconProps: {
                    iconName: "CollapseMenu",
                }, title: "Menu", ariaLabel: "Menu" })),
        React.createElement("div", { style: styles.textContainer }, logoTextRender()),
        React.createElement("div", { style: styles.logoContainer }, logoImageRender()))); };
    var DefaultDeviceRender = function () { return (React.createElement("div", { style: styles.logoContainer },
        logoImageRender(),
        logoTextRender())); };
    return (React.createElement("header", { style: styles.container },
        React.createElement(MediaQuery, { maxWidth: breakpoint.smallDevice }, smallDeviceRender()),
        React.createElement(MediaQuery, { minWidth: breakpoint.smallDevice }, DefaultDeviceRender())));
};
export default React.memo(Header);
