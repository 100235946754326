import metadata from "../../shared/metadata/metadata";
var Utils = /** @class */ (function () {
    function Utils() {
    }
    Utils.setMetaData = function (path) {
        var _a, _b;
        document.title =
            ((_b = metadata === null || metadata === void 0 ? void 0 : metadata.get(((_a = path.split("/").pop()) === null || _a === void 0 ? void 0 : _a.replace("/", "")) || "root")) === null || _b === void 0 ? void 0 : _b.title) ||
                "";
    };
    return Utils;
}());
export default Utils;
