import React, { lazy } from "react";
import { Route, Switch } from "react-router-dom";
import GuidGenerator from "./guid-generator";
var NotFound = lazy(function () { return import("../../not-found/not-found"); });
var GuidGeneratorWrapper = function (_a) {
    var match = _a.match;
    var baseUrl = match.url;
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: baseUrl + "/version4" },
            React.createElement(GuidGenerator, { guidVersion: "4", baseUrl: baseUrl })),
        React.createElement(Route, { exact: true, path: baseUrl + "/version3" },
            React.createElement(GuidGenerator, { guidVersion: "3", baseUrl: baseUrl })),
        React.createElement(Route, { exact: true, path: baseUrl + "/version1" },
            React.createElement(GuidGenerator, { guidVersion: "1", baseUrl: baseUrl })),
        React.createElement(Route, { exact: true, path: baseUrl + "/version5" },
            React.createElement(GuidGenerator, { guidVersion: "5", baseUrl: baseUrl })),
        React.createElement(Route, { exact: true, path: "" + baseUrl },
            React.createElement(GuidGenerator, { guidVersion: "4", baseUrl: baseUrl })),
        React.createElement(Route, { component: NotFound })));
};
export default React.memo(GuidGeneratorWrapper);
