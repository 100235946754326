import React, { lazy, useCallback, useState } from "react";
import componentStyles from "./guid-validator-styles";
var Label = React.lazy(function () {
    return import("@fluentui/react/lib/components/Label/index").then(function (module) { return ({
        default: module.Label,
    }); });
});
var PrimaryButton = React.lazy(function () {
    return import("@fluentui/react/lib/components/Button/PrimaryButton/PrimaryButton").then(function (module) { return ({
        default: module.PrimaryButton,
    }); });
});
var GuidGeneratorDescription = lazy(function () {
    return import("../share-components/guid-generator-descriptions/guid-generator-descriptions");
});
var Flex = lazy(function () { return import("../../../components/flex/flex"); });
var Main = lazy(function () { return import("../../../components/flex/main/main"); });
var Side = lazy(function () { return import("../../../components/flex/side/side"); });
var BigInput = lazy(function () { return import("../../../components/big-input/big-input"); });
var GuidValidator = function () {
    var _a = useState(""), guidValidationResult = _a[0], setGuidValidationResult = _a[1];
    var _b = useState("0"), guidVersion = _b[0], setGuidVersion = _b[1];
    var _c = useState(""), inputValue = _c[0], setInputValue = _c[1];
    var validator = function () {
        var uuid = inputValue;
        var regexWithHyphens = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
        var regexWithoutHyphens = /^[0-9a-f]{8}[0-9a-f]{4}[0-9a-f]{4}[0-9a-f]{4}[0-9a-f]{12}$/i;
        var regex;
        var group;
        if (regexWithHyphens.test(uuid)) {
            regex = regexWithHyphens;
            group = uuid.substring(14, 18);
        }
        else if (regexWithoutHyphens.test(uuid)) {
            regex = regexWithoutHyphens;
            group = uuid.substring(12, 16);
        }
        if (!regex || !group) {
            setGuidVersion("0");
            setGuidValidationResult("Uuid value is not valid.");
        }
        else {
            // eslint-disable-next-line no-bitwise
            var version = (parseInt(group, 16) >> 12) & 0xf;
            setGuidVersion(version.toString());
            setGuidValidationResult("Uuid value is valid.Version: " + version);
        }
    };
    var onChange = useCallback(function (e) {
        if (e.target) {
            setInputValue(e.target.value);
        }
        setGuidValidationResult("");
    }, []);
    return (React.createElement(Flex, null,
        React.createElement(Main, null,
            React.createElement("h1", null, "UUID Validator"),
            React.createElement("div", null,
                React.createElement("div", { style: componentStyles.guidBigInputDiv },
                    React.createElement(BigInput, { readOnly: false, onChange: onChange }),
                    React.createElement("div", null,
                        React.createElement(PrimaryButton, { disabled: inputValue === "", title: "Validate a UUID value", style: componentStyles.validateGuidButton, onClick: function () { return validator(); } }, "Validate")),
                    React.createElement("div", null,
                        React.createElement(Label, { style: componentStyles.result }, guidValidationResult))))),
        React.createElement(Side, null,
            React.createElement(GuidGeneratorDescription, { version: guidVersion, showAll: true }))));
};
export default React.memo(GuidValidator);
