var componentStyle = {
    container: {
        marginTop: "3rem",
    },
    textField: {
        root: {
            width: "10rem",
            display: "inline-block",
        },
    },
    text: {
        root: {
            fontSize: "2rem",
            margin: ".5rem",
        },
    },
    checkBox: {
        root: {
            marginTop: "1rem",
            width: "10rem",
        },
    },
};
export default componentStyle;
