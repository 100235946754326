import React, { lazy, useCallback, useState } from "react";
import { getColorFromString } from "@fluentui/react";
import descriptionData from "./description-data";
import componentStyle from "./color-picker-style";
import palette from "../../../themes";
var CommonSide = lazy(function () { return import("../../../components/common-side/common-side"); });
var CopyLabel = lazy(function () { return import("../../../components/copy-label/copy-label"); });
var Flex = lazy(function () { return import("../../../components/flex/flex"); });
var Main = lazy(function () { return import("../../../components/flex/main/main"); });
var Side = lazy(function () { return import("../../../components/flex/side/side"); });
var ColorPicker = React.lazy(function () {
    return import("@fluentui/react/lib/components/ColorPicker/index").then(function (module) { return ({
        default: module.ColorPicker,
    }); });
});
var Checkbox = React.lazy(function () {
    return import("@fluentui/react/lib/components/Checkbox/index").then(function (module) { return ({
        default: module.Checkbox,
    }); });
});
var HexRbg = function () {
    var _a;
    var infoWidth = 150;
    var _b = useState(false), cssValues = _b[0], setCssValues = _b[1];
    var _c = useState(getColorFromString(((_a = palette.palette) === null || _a === void 0 ? void 0 : _a.themePrimary) || "#FFF")), color = _c[0], setColor = _c[1];
    var onColorChange = React.useCallback(function (_ev, colorObj) {
        setColor(colorObj);
    }, []);
    var onCssValueChange = useCallback(function (_ev, isChecked) {
        setCssValues(isChecked || false);
    }, []);
    var getHexValue = useCallback(function (hexValue) { return (cssValues ? "#" + hexValue : hexValue); }, [cssValues]);
    var getRbgValue = useCallback(function (r, g, b, a) {
        var rbga = r + "," + g + "," + b + (a ? "," + a / 100 : "");
        return cssValues ? "rbg" + (a ? "a" : "") + "(" + rbga + ")" : rbga;
    }, [cssValues]);
    if (!color) {
        return null;
    }
    return (React.createElement(Flex, null,
        React.createElement(Main, null,
            React.createElement("h1", null, "Color Picker"),
            React.createElement(Flex, null,
                React.createElement(Main, null,
                    React.createElement(ColorPicker, { color: color, onChange: onColorChange, alphaType: "alpha", showPreview: true, 
                        // styles={colorPickerStyles}
                        // The ColorPicker provides default English strings for visible text.
                        // If your app is localized, you MUST provide the `strings` prop with localized strings.
                        strings: {
                            // By default, the sliders will use the text field labels as their aria labels.
                            // Previously this example had more detailed instructions in the labels, but this is
                            // a bad practice and not recommended. Labels should be concise, and match visible text when possible.
                            hueAriaLabel: "Hue",
                        } })),
                React.createElement(Side, null,
                    React.createElement("div", { style: componentStyle.container },
                        React.createElement("div", null,
                            React.createElement("span", null, "Hex:"),
                            React.createElement(CopyLabel, { id: "hex", width: infoWidth, readonlyValue: getHexValue(color.hex) })),
                        React.createElement("div", null,
                            React.createElement("span", null, "RGB:"),
                            React.createElement(CopyLabel, { id: "rgb", width: infoWidth, readonlyValue: getRbgValue(color.r, color.g, color.b) })),
                        color.a && (React.createElement("div", null,
                            React.createElement("span", null, "RGBA:"),
                            React.createElement(CopyLabel, { id: "rgba", width: infoWidth, readonlyValue: getRbgValue(color.r, color.g, color.b, color.a) })))),
                    React.createElement(Checkbox, { label: "CSS Values", styles: componentStyle.checkBox, onChange: onCssValueChange })))),
        React.createElement(Side, null,
            React.createElement(CommonSide, { data: descriptionData.data }))));
};
export default React.memo(HexRbg);
