var data = {
    data: [
        {
            title: "What is a Hexadecimal color value (Hex)?",
            description: "A Hexadecimal color value is used in HTML or CSS to define a color on a web page. The Hexadecimal color value is formatted as #RRGGBB and is a mix of three color components. RR is red, GG is green, and BB is blue. Each of the color components (RR, GG, and BB) is a value between 00 and FF in hexadecimal.",
        },
        {
            title: "What is an RGB color value (RGB)?",
            description: "An RGB color value is used in HTML or CSS to define a color on a web page. The RGB color value is a mix of three color components. R is red, G is Green, and B is blue. Each of the color components (R, G, and B) is a value between 0 and 255 in decimal.",
        },
        {
            title: "What is an RGBA color value (RGBA)?",
            description: "The RGBA color space is a color space that includes an extra channel (alpha channel) for representing the transparency information of an image. An RGBA color is composed of four channels, where each channel is represented by 8 bits. In RGBA, the transparency information is stored in the alpha channel, and the color information is stored in the three RGB channels.",
        },
    ],
};
export default data;
