var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import StyleBase from "../../common/styleBase";
import BaseColors from "../../constants/base-colors";
var ComponentStyles = /** @class */ (function (_super) {
    __extends(ComponentStyles, _super);
    function ComponentStyles() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.componentStylesBase = {
            logo: {
                margin: "0 10px",
                width: "33px",
            },
            container: {
                alignItems: "center",
                backgroundColor: BaseColors.secundary,
                display: "flex",
                height: "3.5rem",
                fontSize: "24px",
                color: BaseColors.quaternaryColor,
                minHeight: "3.5rem",
                width: "100%",
            },
            menuContainer: {
                width: "3rem",
            },
            menu: {
                root: {
                    backgroundColor: "transparent",
                    color: "white",
                },
                rootFocused: {
                    backgroundColor: "transparent",
                    color: "white",
                },
                icon: {
                    fontSize: "1.4rem",
                },
            },
            logoContainer: {
                display: "flex",
                alignContent: "center",
                width: "100%",
            },
        };
        _this.componentStylesSmallDevice = __assign(__assign({}, _this.componentStylesBase), { container: __assign(__assign({}, _this.componentStylesBase.container), { justifyContent: "flex-end", textAlign: "right" }), menuContainer: {
                marginLeft: "0.5rem",
            }, textContainer: {
                flexGrow: 2,
                textAlign: "center",
            }, logoContainer: {} });
        return _this;
    }
    return ComponentStyles;
}(StyleBase));
export default ComponentStyles;
