var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import StyleBase from "../../common/styleBase";
import BaseColors from "../../constants/base-colors";
import Sizes from "../../constants/sizes";
var ComponentStyles = /** @class */ (function (_super) {
    __extends(ComponentStyles, _super);
    function ComponentStyles() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.componentStylesBase = {
            container: {
                borderLeft: 0,
                borderBottom: 0,
                borderTop: 0,
                borderRight: "1px",
                borderRightColor: BaseColors.primaryColor,
                borderStyle: "solid",
                display: "flex",
                flexDirection: "column",
                maxWidth: "13rem",
                width: "13rem",
            },
            navContainer: {
                flex: 1,
            },
            navigationStyles: {
                root: {
                    flex: 1,
                },
                navItem: {
                // height: "35%",
                },
                link: {
                    height: "35px",
                    lineHeight: "35px",
                },
                chevronButton: {
                    height: "35px",
                    lineHeight: "35px",
                },
                chevronIcon: {
                    height: "35px",
                    lineHeight: "35px",
                },
            },
            moreTools: {
                display: "grid",
                fontSize: "14px",
                marginBottom: "1.5rem",
                textAlign: "center",
            },
        };
        _this.componentStylesSmallDevice = __assign(__assign({}, _this.componentStylesBase), { container: {
                backgroundColor: BaseColors.tertiaryColor,
                height: "calc(100% - " + Sizes.headerHeight + ")",
                position: "absolute",
                zIndex: 99,
            } });
        return _this;
    }
    return ComponentStyles;
}(StyleBase));
export default ComponentStyles;
