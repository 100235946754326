var componentStyles = {
    guidBigInputDiv: {
        textAlign: 'center',
        padding: '2rem'
    },
    copyButton: {
        marginLeft: '1rem'
    },
    validateGuidButton: {
        marginTop: '1rem'
    },
    result: {
        marginTop: '2rem',
        fontSize: '22px'
    }
};
export default componentStyles;
