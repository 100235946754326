var guidGeneratorVersion4MetaData = {
    description: "UUID Generator tool allow to generate the UUID (globally unique identifier) strings in all the available versions (UUID 1, UUID 3, UUID 4, UUID 5).",
    title: "UUID Generator",
};
var guidValidatorMetaData = {
    description: "This online tool allows you to validated the UUID (globally unique identifier) string in all the available versions (UUID 1, UUID 3, UUID 4, UUID 5). ",
    title: "UUID Validator",
};
var metadata = new Map([
    [
        "root",
        {
            description: "Useful mini tools for developers in a web site, UUID Generator, UUID Validator, Epoch Time Converter, Url Encoding, Base64 Encoding, Svg Viewer, Dev Tools",
            title: "Mini tools for developers",
        },
    ],
    ["guidvalidator", guidValidatorMetaData],
    ["uuidvalidator", guidValidatorMetaData],
    ["guidgenerator", guidGeneratorVersion4MetaData],
    ["uuidgenerator", guidGeneratorVersion4MetaData],
    ["version4", guidGeneratorVersion4MetaData],
    [
        "version1",
        {
            description: "UUID Generator tool allow to generate the UUID (globally unique identifier) strings in using version 1.",
            title: "UUID Generator - Version 1",
        },
    ],
    [
        "version3",
        {
            description: "UUID Generator tool allow to generate the UUID (globally unique identifier) strings in using version 3.",
            title: "UUID Generator - Version 3",
        },
    ],
    [
        "version5",
        {
            description: "UUID Generator tool allow to generate the UUID (globally unique identifier) strings in using version 5.",
            title: "UUID Generator - Version 5",
        },
    ],
    [
        "epochconverter",
        {
            description: "Epoch Time Converter tool allow to convert epoch unix time (millisecond/seconds) to UTC/LocalTime date time.",
            title: "Epoch Time Converter",
        },
    ],
    [
        "urlencoding",
        {
            description: "Url Encoding tool allows you to Encode/Decode URL strings.",
            title: "Url Encoding",
        },
    ],
    [
        "base64encoding",
        {
            description: "Base64 Encode Decode tool allow to encode/decode a plain text to base64",
            title: "Base64 Encode and Decode",
        },
    ],
    [
        "svgviewer",
        {
            description: "Svg Viewer tool allows to easily vizualize a preview image of SVG code. ",
            title: "Svg Viewer",
        },
    ],
    [
        "colorpicker",
        {
            description: "Online tool for picking any color and getting the RGB and Hex values for the select color.",
            title: "Color Picker",
        },
    ],
    [
        "babymode",
        {
            description: "Fullscreen mode for your baby. Browser will catch all posible keys and print them on the screen with a variaty of colors.",
            title: "Baby Mode",
        },
    ],
]);
export default metadata;
