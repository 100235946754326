var defaultWidth = 500;
var componentStyles = {
    dropdown: {
        dropdown: {
            width: 100,
        },
    },
    guidBigInputDiv: {
        margin: "1rem 0",
        position: "relative",
    },
    copyButton: {
        marginLeft: "1rem",
    },
    generateGuidButton: {
        marginTop: "2rem",
    },
    namespaceComboBox: {
        width: defaultWidth,
    },
    nameTextField: {
        fieldGroup: [
            {
                width: defaultWidth,
            },
        ],
        subComponentStyles: {
            label: {
                root: {
                    width: defaultWidth,
                },
            },
        },
    },
    guidOptions: {
        margin: "0 auto",
        width: "7rem",
    },
    checkBox: {
        root: {
            width: "1.5rem",
        },
    },
    guidGeneratorContainer: {
        textAlign: "center",
    },
};
export default componentStyles;
