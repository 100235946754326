import { DeviceEnum } from '../enums/enums';
var StyleBase = /** @class */ (function () {
    function StyleBase(deviceBreakpoint) {
        this.deviceBreakpoint = DeviceEnum.Big;
        this.deviceBreakpoint = deviceBreakpoint;
    }
    StyleBase.prototype.getStyle = function () {
        switch (this.deviceBreakpoint) {
            case DeviceEnum.Small:
                return this.componentStylesSmallDevice;
            default:
                return this.componentStylesBase;
        }
    };
    return StyleBase;
}());
export default StyleBase;
